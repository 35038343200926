// ** Icons Import
import {
  Clock,
  Plus,
  UploadCloud,
  Users,
  Circle,
  Database,
  Tool,
  AlertTriangle,
  PenTool
} from 'react-feather'

export default [
  {
    id: 'contacts',
    title: 'Contacts',
    icon: <Users size={20} />,
    children: [
      {
        id: 'contactList',
        title: 'Liste',
        icon: <Database size={12} />,
        navLink: '/crm/contacts'
      },
      {
        id: 'contactAdd',
        title: 'Ajouter',
        icon: <Plus size={12} />,
        navLink: '/crm/contact/new'
      }
    ]
  },
  {
    id: 'delais',
    title: 'Délais',
    icon: <Clock size={20} />,
    children: [
      {
        id: 'delaiList',
        title: 'Liste',
        icon: <Database size={12} />,
        navLink: '/crm/delais'
      },
      {
        id: 'delaiAdd',
        title: 'Ajouter',
        icon: <Plus size={12} />,
        navLink: '/crm/delai/new'
      }
    ]
  },
  {
    id: 'rappels',
    title: 'Rappels',
    icon: <AlertTriangle size={20} />,
    children: [
      {
        id: 'rappelList',
        title: 'Liste',
        icon: <Database size={12} />,
        navLink: '/crm/rappels'
      },
      {
        id: 'rappelAdd',
        title: 'Ajouter',
        icon: <Plus size={12} />,
        navLink: '/crm/rappel/new'
      }
    ]
  },
  {
    id: 'users',
    title: 'Utilisateurs',
    icon: <PenTool size={20} />,
    children: [
      {
        id: 'userList',
        title: 'Liste',
        icon: <Database size={12} />,
        navLink: '/crm/users'
      },
      {
        id: 'userAdd',
        title: 'Ajouter',
        icon: <Plus size={12} />,
        navLink: '/crm/user/new'
      }
    ]
  },
  {
    id: 'outils',
    title: 'Outils',
    icon: <Tool size={20} />,
    className: 'mb-1',
    children: [
      {
        id: 'importTools',
        title: 'Import',
        icon: <UploadCloud size={12} />,
        navLink: '/crm/import'
      }
    ]
  },
  {
    header: ''
  }
]
