// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const { jwt } = useJwt({
  loginEndpoint:
    'https://crmwp.solvoxia-avocats.fr/wp-json/api-bearer-auth/v1/login',
  refreshEndpoint:
    'https://crmwp.solvoxia-avocats.fr/wp-json/api-bearer-auth/v1/tokens/refresh',

  // loginEndpoint:
  //   'https://client.pyweb.fr/62f4e4b425099/wp-json/api-bearer-auth/v1/login',
  // refreshEndpoint:
  //   'https://client.pyweb.fr/62f4e4b425099/wp-json/api-bearer-auth/v1/tokens/refresh',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageExpireTokenKeyName: 'expireToken',
  dureeInactivite: 3600 //en secondes, si user inactif pendant x secondes -> déconnexion
})

export default jwt
