// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

import UserDropdown from './UserDropdown'
import { Fragment } from 'react'

// ** Reactstrap Imports
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown
} from 'reactstrap'

import * as Icon from 'react-feather'

const CustomNavbar = (props) => {
  const { setMenuVisibility } = props
  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <ul className='navbar-nav d-xl-none'>
          <NavItem className='mobile-menu me-auto'>
            <NavLink
              className='nav-menu-main menu-toggle hidden-xs is-active'
              onClick={() => setMenuVisibility(true)}>
              <Icon.Menu className='ficon' />
            </NavLink>
          </NavItem>
        </ul>
      </div>
      <ul className='nav navbar-nav align-items-center ms-auto'>
        <UserDropdown />
      </ul>
    </Fragment>
  )
}

const CustomFooter = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-start d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a
          href='https://www.solvoxia-avocats.com/'
          target='_blank'
          rel='noopener noreferrer'>
          Solvoxia Avocats
        </a>
        <span className='d-none d-sm-inline-block'>, Tous droits réservés</span>
      </span>
      <span className='float-md-end d-none d-md-block'>
        Développé par{' '}
        <a href='https://pycroyal.fr' target='_blank' rel='noopener noreferrer'>
          PyCroyal
        </a>
      </span>
    </p>
  )
}

const VerticalLayout = (props) => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout
      menuData={navigation}
      navbar={(props) => <CustomNavbar {...props} />}
      footer={<CustomFooter />}
      {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
