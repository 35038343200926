// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/HorizontalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/horizontal'

const CustomFooter = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-start d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a
          href='https://www.solvoxia-avocats.com/'
          target='_blank'
          rel='noopener noreferrer'>
          Solvoxia Avocats
        </a>
        <span className='d-none d-sm-inline-block'>, Tous droits réservés</span>
      </span>
      <span className='float-md-end d-none d-md-block'>
        Développé par{' '}
        <a href='https://pycroyal.fr' target='_blank' rel='noopener noreferrer'>
          PyCroyal
        </a>
      </span>
    </p>
  )
}

const HorizontalLayout = (props) => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout menuData={navigation} footer={<CustomFooter />} {...props}>
      <Outlet />
    </Layout>
  )
}

export default HorizontalLayout
