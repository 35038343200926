/* Fonctions utiles pour la récupération des données de la base WP */
import axios from 'axios'
import themeConfig from '../configs/themeConfig'

export const getAllCptRequest = async (type) => {
  const cpt = type
  const perpage = 100
  let npage = 1
  const url = `${themeConfig.app.appEndpoint}/${cpt}?page=${npage}&per_page=${perpage}&_fields=id,title,acf`
  const result = await axios.get(url)
  const data = result.data

  const totalPages = result.headers['x-wp-totalpages']

  let response = data

  npage++
  while (npage <= totalPages) {
    const url = `${themeConfig.app.appEndpoint}/${cpt}?page=${npage}&per_page=${perpage}&_fields=id,title,acf`
    const { data } = await axios.get(url)
    response = [...response, ...data]
    npage++
  }

  //   const postsClean = response.map(({ id, title, acf }) => {
  //     return {
  //       id: parseInt(id),
  //       title: title.rendered,
  //       ...acf
  //     }
  //   })
  const postsClean = response.map(({ id, acf }) => {
    return {
      id: parseInt(id),
      ...acf
    }
  })

  return postsClean
}

export const getUsers = async () => {
  const perpage = 100
  let npage = 1
  const url = `${themeConfig.app.appEndpoint}/users?page=${npage}&per_page=${perpage}`
  const result = await axios.get(url)
  const data = result.data

  const totalPages = result.headers['x-wp-totalpages']

  let response = data

  npage++
  while (npage <= totalPages) {
    const url = `${themeConfig.app.appEndpoint}/users?page=${npage}&per_page=${perpage}`
    const { data } = await axios.get(url)
    response = [...response, ...data]
    npage++
  }

  response = response.filter((obj) => obj.id !== 1) //on retire user id = 1
  return response
}

export const getPost = async (args) => {
  const { id, cpt } = args

  const url = `${themeConfig.app.appEndpoint}/${cpt}/${id}`
  const result = await axios.get(url)
  const { data } = result

  if (cpt === 'users') return data

  const { acf } = data
  const post = {
    id: parseInt(id),
    ...acf
  }

  return post
}

export const addPost = async (args) => {
  const { post, cpt } = args

  const url = `${themeConfig.app.appEndpoint}/${cpt}`
  const result = await axios.post(url, post)
  const { data } = result
  const { id, acf } = data

  const newPost = {
    id: parseInt(id),
    ...acf
  }

  return newPost
}

export const updatePost = async (args) => {
  const { id, post, cpt } = args
  const url = `${themeConfig.app.appEndpoint}/${cpt}/${id}`
  const result = await axios.post(url, post)
  const { data } = result

  const { acf } = data

  const updatedPost = {
    id: parseInt(id),
    ...acf
  }

  return updatedPost
}

export const addWPUser = async (args) => {
  const url = `${themeConfig.app.appEndpoint}/users`
  const result = await axios.post(url, args)
  const { data } = result

  return data
}

export const updateWPUser = async (args) => {
  const url = `${themeConfig.app.appCustomEndpoint}/updateuser`
  const result = await axios.post(url, args)
  const { data } = result

  return data
}

export const deleteWPUser = async (args) => {
  const url = `${themeConfig.app.appCustomEndpoint}/deleteuser`
  const result = await axios.post(url, args)
  const { data } = result

  console.log(data)
  return data
}

export const deletePost = async (args) => {
  const { id } = args
  const url = `${themeConfig.app.appCustomEndpoint}/deletepost`

  const result = await axios.post(url, args)
  const { data } = result
  const { acf } = data

  const deletedPost = {
    id: parseInt(id),
    ...acf
  }

  return deletedPost
}

export const getAllMetaValues = async (args) => {
  const { type, key } = args
  const url = `${themeConfig.app.appCustomEndpoint}/getmeta?type=${type}&key=${key}`
  const { data } = await axios.get(url)

  return data
}

export const getOption = async (args) => {
  const { key } = args

  const url = `${themeConfig.app.appCustomEndpoint}/getoption?key=${key}`
  const result = await axios.get(url)
  const { data } = result

  return data
}

export const updateOption = async (args) => {
  const url = `${themeConfig.app.appCustomEndpoint}/updateoption`
  const result = await axios.post(url, args)
  const { data } = result

  return data
}

// export const handleDeleteClick = () => {
//   return MySwal.fire({
//     title: 'Êtes-vous sûr ?',
//     text: 'Vous ne pourrez pas restaurer le contact !',
//     icon: 'warning',
//     showCancelButton: true,
//     cancelButtonText: 'Annuler',
//     confirmButtonText: 'Oui, supprimer le contact !',
//     customClass: {
//       confirmButton: 'btn btn-primary',
//       cancelButton: 'btn btn-outline-danger ms-1'
//     },
//     buttonsStyling: false
//   }).then(function (result) {
//     if (result.value) {
//       dispatch(deleteContact(selectedContact.id)).then((action) => {
//         if ('error' in action && !isObjEmpty(action.error)) {
//           toast((t) => (
//             <ToastErrorContent t={t} message={action.error.message} />
//           ))
//         } else {
//           MySwal.fire({
//             icon: 'success',
//             title: 'Supprimé !',
//             text: 'Le contact a été supprimé.',
//             customClass: {
//               confirmButton: 'btn btn-success'
//             }
//           })
//         }
//       })
//     } else if (result.dismiss === MySwal.DismissReason.cancel) {
//       MySwal.fire({
//         title: 'Annulé',
//         text: 'Suppression annulée :)',
//         icon: 'error',
//         customClass: {
//           confirmButton: 'btn btn-success'
//         }
//       })
//     }
//   })
// }
