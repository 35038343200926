/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-confusing-arrow */

import { DefaultRoute } from '../router/routes'
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const isUserLoggedIn = () => {
  return getUserData()
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return DefaultRoute
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// ** Diverses fonctions tri, filtres, dates...
export const uniqValues = (array, field) => {
  return array.reduce((accumulator, current) => {
    if (!accumulator.includes(current[field]) && current[field]) {
      accumulator.push(current[field])
    }

    return accumulator
  }, [])
}

export const uniqValuesToOptions = (array, field) => {
  let result = array.reduce((accumulator, current) => {
    if (!accumulator.includes(current[field]) && current[field]) {
      accumulator.push(current[field])
    }

    return accumulator
  }, [])

  result = result.map((val) => {
    return { value: val, label: val }
  })
  //result.unshift({ value: '', label: '-' })

  return result
}

export const valuesToOptions = (array) => {
  const arrayToFilter = array

  if (arrayToFilter.length > 0) {
    const result = arrayToFilter.map((val) => {
      return { value: val, label: val }
    })
    // result.unshift({ value: '', label: '-' })

    return result
  }

  return []
}

export const delaisToOtpions = (array) => {
  const arrayToFilter = array

  if (arrayToFilter.length > 0) {
    const result = arrayToFilter.map((post) => {
      return { value: post.id, label: post.intitule }
    })
    result.unshift({
      value: 0,
      label: 'Aucun'
    })

    return result
  }

  return []
}

export const filterArray = (array, filters) => {
  const filterKeys = Object.keys(filters)
  let filteredArray = array

  const selectFilters = ['metier', 'statut', 'structure'] //filtres select
  const cbFilters = ['newsletter', 'voeux'] //filtres checkbox -> true/false

  filterKeys.forEach((key) => {
    filteredArray = filteredArray.filter((item) => {
      if (selectFilters.includes(key)) {
        if (item[key].toLowerCase() === filters[key].toLowerCase()) return true
        else return null
      } else if (cbFilters.includes(key) && filters[key]) {
        if (item[key]) return true
        else return null
      } else if (!selectFilters.includes(key) && !cbFilters.includes(key)) {
        const startsWith = item[key]
          .toLowerCase()
          .startsWith(filters[key].toLowerCase())

        const includes = item[key]
          .toLowerCase()
          .includes(filters[key].toLowerCase())

        if (startsWith) {
          return startsWith
        } else if (!startsWith && includes) {
          return includes
        } else return null
      }
    })
  })

  return filteredArray
}

export const sortByKey = (array, key, sort) => {
  const sortedArray = array.slice()

  // return sortedArray
  return sortedArray.sort((a, b) => {
    const valA = a
    const valB = b

    const x = valA[key]
    const y = valB[key]

    // if (key.includes('date')) {
    //   x = x.split('/').reverse().join('')
    //   y = y.split('/').reverse().join('')
    // }

    if (sort === 'asc') return x < y ? -1 : x > y ? 1 : 0
    if (sort === 'desc') return x > y ? -1 : x < y ? 1 : 0
  })
}

export const filterArrayBetween = (array, filters) => {
  const arrayToFilter = array
  const { dateRepere, key } = filters

  const firstDay = getFirstDayOfWeek(dateRepere)
  const firstDayString = firstDay
    .toLocaleString('fr', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit'
    })
    .split('/')
    .reverse()
    .join('')
  const lastDay = getLastDayOfWeek(dateRepere)
  const lastDayString = lastDay
    .toLocaleString('fr', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit'
    })
    .split('/')
    .reverse()
    .join('')

  const result = arrayToFilter.filter((item) => {
    return (
      parseInt(item[key]) >= parseInt(firstDayString) &&
      parseInt(item[key]) <= parseInt(lastDayString)
    )
  })

  return result
}

export const getObject = (arr, searchId) => {
  const arrayToFilter = arr
  const result = arrayToFilter.find((obj) => obj.id === parseInt(searchId))

  return result
}

export const strToArray = (str, sep = ',') => {
  return str ? str.split(sep) : []
}

export const ucFirst = (str = '') => {
  if (str.length > 0) {
    return str[0].toUpperCase() + str.substring(1)
  } else {
    return str
  }
}

export const onlySpaces = (str) => {
  if (typeof str === 'string') {
    return str.trim().length === 0
  } else {
    return false
  }
}

export const convertToDate = (dateString) => {
  //  Convert a "dd/MM/yyyy" string into a Date object
  const d = dateString.split('/')
  const dat = new Date(`${d[2]}/${d[1]}/${d[0]}`)
  return dat
}

export const convertdmYToUnix = (dateString) => {
  //  Convert a "dd/MM/yyyy" string into a Unix

  if (dateString && typeof dateString === 'string') {
    const d = dateString.split('/')
    const unix = new Date(`${d[2]}/${d[1]}/${d[0]}`).getTime() / 1000
    return unix
  } else {
    return ''
  }
}

export const getObjDay = (valUnix = '') => {
  const date = valUnix ? new Date(valUnix * 1000) : new Date()
  const unix = valUnix ? valUnix : new Date().getTime() / 1000

  const objDay = {
    date,
    unix,
    dateYmd: date
      .toLocaleString('fr', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit'
      })
      .split('/')
      .reverse()
      .join(''),
    datedmY: date.toLocaleString('fr', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    dateDay: date.toLocaleString('fr', {
      day: '2-digit'
    }),
    dateMonth: date.toLocaleString('fr', {
      month: '2-digit'
    }),
    dateString: date.toLocaleString('fr', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }),
    dayString: date.toLocaleString('fr', {
      weekday: 'long'
    })
  }

  return objDay
}

export const getTypesEcheance = (array) => {
  const allEcheances = []
  array.map((contact) => {
    const echeances =
      contact.echeances !== '' ? JSON.parse(contact.echeances) : []

    if (echeances.length > 0) {
      echeances.map((echeance) => {
        allEcheances.push(echeance)
      })
    }
  })

  return uniqValues(allEcheances, 'type')
}

export const addMonthsToDate = (dateUnix, months) => {
  const date = new Date(dateUnix * 1000)
  date.setMonth(date.getMonth() + months)

  return date.getTime() / 1000
}
