/* eslint-disable implicit-arrow-linebreak */
// ** React Imports
import { lazy } from 'react'

const TableContacts = lazy(() =>
  import('../../crm/table-contacts/TableContacts')
)
const ContactPreview = lazy(() => import('../../crm/preview-contact'))
const ContactNew = lazy(() => import('../../crm/new-contact'))

const TableDelais = lazy(() => import('../../crm/table-delais/TableDelais'))
const DelaiEdit = lazy(() => import('../../crm/edit-delai'))
const DelaiNew = lazy(() => import('../../crm/new-delai'))

const TableRappels = lazy(() => import('../../crm/table-rappels/TableRappels'))
const RappelEdit = lazy(() => import('../../crm/edit-rappel'))
const RappelNew = lazy(() => import('../../crm/new-rappel'))

const TableUsers = lazy(() => import('../../crm/table-users/TableUsers'))
const UserEdit = lazy(() => import('../../crm/edit-user'))
const UserNew = lazy(() => import('../../crm/new-user'))

const Import = lazy(() => import('../../crm/import/Import'))

const CrmRoutes = [
  {
    element: <TableContacts />,
    path: '/crm/contacts'
  },
  {
    element: <ContactPreview />,
    path: '/crm/contact/preview/:id'
  },
  {
    element: <ContactNew />,
    path: '/crm/contact/new'
  },
  {
    element: <TableDelais />,
    path: '/crm/delais'
  },
  {
    element: <DelaiEdit />,
    path: '/crm/delai/edit/:id'
  },
  {
    element: <DelaiNew />,
    path: '/crm/delai/new'
  },
  {
    element: <TableRappels />,
    path: '/crm/rappels'
  },
  {
    element: <RappelEdit />,
    path: '/crm/rappel/edit/:id'
  },
  {
    element: <RappelNew />,
    path: '/crm/rappel/new'
  },
  {
    element: <TableUsers />,
    path: '/crm/users'
  },
  {
    element: <UserEdit />,
    path: '/crm/user/edit/:id'
  },
  {
    element: <UserNew />,
    path: '/crm/user/new'
  },
  {
    element: <Import />,
    path: '/crm/import'
  }
]

export default CrmRoutes
