// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'CRM',
    appVersion: '1.0',
    appLogoImage: require('@src/assets/images/logo/logo_simple.png').default,
    appUrl: 'https://crm.solvoxia-avocats.fr/',
    // appEndpoint: 'https://client.pyweb.fr/62f4e4b425099/wp-json/wp/v2',
    // appCustomEndpoint: 'https://client.pyweb.fr/62f4e4b425099/wp-json/app/v1'
    appEndpoint: 'https://crmwp.solvoxia-avocats.fr/wp-json/wp/v2',
    appCustomEndpoint: 'https://crmwp.solvoxia-avocats.fr/wp-json/app/v1'
  },
  layout: {
    isRTL: false,
    skin: 'bordered', // light, dark, bordered, semi-dark
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'top-right' // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
