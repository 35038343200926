/* eslint-disable no-unused-vars */
/* eslint-disable multiline-ternary */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {} from 'react-redux'

// ** Uniqid Import
import uniqid from 'uniqid'

import {
  getOption,
  updateOption,
  addPost,
  updatePost,
  getPost,
  deletePost,
  getAllCptRequest,
  getUsers,
  addWPUser,
  updateWPUser,
  deleteWPUser
} from '@wpdb'

import { sortByKey, uniqValues, getTypesEcheance } from '@utils'

export const getAllData = createAsyncThunk('appCRM/getAllData', async () => {
  let getlistContacts = await getAllCptRequest('contacts')
  let getlistDelais = await getAllCptRequest('delais')
  let getlistRappels = await getAllCptRequest('rappels')
  const getlistUsers = await getUsers()

  getlistContacts = sortByKey(getlistContacts, 'nom', 'asc')
  getlistDelais = sortByKey(getlistDelais, 'intitule', 'asc')
  getlistRappels = sortByKey(getlistRappels, 'intitule', 'asc')

  return {
    contacts: getlistContacts,
    delais: getlistDelais,
    rappels: getlistRappels,
    users: getlistUsers,
    emails: uniqValues(getlistUsers, 'user_email')
  }
})

export const getContact = createAsyncThunk('appCRM/getContact', async (id) => {
  const args = {
    id: parseInt(id),
    cpt: 'contacts'
  }

  const post = await getPost(args)

  return post
})

export const addContact = createAsyncThunk(
  'appCRM/addContact',
  async (params) => {
    const { newContact } = params
    const cpt = 'contacts'

    const args = {
      post: newContact,
      cpt
    }

    const newPost = await addPost(args)

    return newPost
  }
)

export const updateContact = createAsyncThunk(
  'appCRM/updateContact',
  async (params) => {
    const { editPost, id } = params
    const cpt = 'contacts'

    const args = {
      id: parseInt(id),
      post: editPost,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const deleteContact = createAsyncThunk(
  'appCRM/deleteContact',
  async (id) => {
    const cpt = 'contacts'
    const args = {
      id: parseInt(id),
      cpt
    }

    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const getDelai = createAsyncThunk('appCRM/getDelai', async (id) => {
  const args = {
    id: parseInt(id),
    cpt: 'delais'
  }

  const post = await getPost(args)

  return post
})

export const addDelai = createAsyncThunk('appCRM/addDelai', async (params) => {
  const { newDelai } = params
  const cpt = 'delais'

  const args = {
    post: newDelai,
    cpt
  }

  const newPost = await addPost(args)

  return newPost
})

export const updateDelai = createAsyncThunk(
  'appCRM/updateDelai',
  async (params) => {
    const { editPost, id } = params
    const cpt = 'delais'

    const args = {
      id: parseInt(id),
      post: editPost,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const deleteDelai = createAsyncThunk(
  'appCRM/deleteDelai',
  async (id) => {
    const cpt = 'delais'
    const args = {
      id: parseInt(id),
      cpt
    }

    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const getRappel = createAsyncThunk('appCRM/getRappel', async (id) => {
  const args = {
    id: parseInt(id),
    cpt: 'rappels'
  }

  const post = await getPost(args)

  return post
})

export const addRappel = createAsyncThunk(
  'appCRM/addRappel',
  async (params) => {
    const { newRappel } = params
    const cpt = 'rappels'

    const args = {
      post: newRappel,
      cpt
    }

    const newPost = await addPost(args)

    return newPost
  }
)

export const updateRappel = createAsyncThunk(
  'appCRM/updateRappel',
  async (params) => {
    const { editPost, id } = params
    const cpt = 'rappels'

    const args = {
      id: parseInt(id),
      post: editPost,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const deleteRappel = createAsyncThunk(
  'appCRM/deleteRappel',
  async (id) => {
    const cpt = 'rappels'
    const args = {
      id: parseInt(id),
      cpt
    }

    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const getUser = createAsyncThunk('appCRM/getUser', async (id) => {
  const args = {
    id: parseInt(id),
    cpt: 'users'
  }

  const post = await getPost(args)

  return post
})

export const addUser = createAsyncThunk('appCRM/addUser', async (params) => {
  const { newUser } = params

  const args = newUser

  const addedUser = await addWPUser(args)

  return addedUser
})

export const updateUser = createAsyncThunk(
  'appCRM/updateUser',
  async (params) => {
    const { editUser, id } = params

    const args = {
      id: parseInt(id),
      user_email: editUser.user_email,
      password: editUser.password
    }

    const updatedUser = await updateWPUser(args)

    return updatedUser
  }
)

export const deleteUser = createAsyncThunk(
  'appCRM/deleteUser',
  async (params) => {
    const { id } = params
    const args = {
      id: parseInt(id)
    }

    await deleteWPUser(args)

    return parseInt(id)
  }
)

export const updateAppOption = createAsyncThunk(
  'appCRM/updateAppOption',
  async (params) => {
    const { key } = params
    const option = await updateOption(params)

    return {
      key,
      val: option
    }
  }
)

export const getAppOption = createAsyncThunk(
  'appCRM/getAppOption',
  async (params) => {
    const { key } = params
    const option = await getOption(params)

    return {
      key,
      val: option
    }
  }
)

const date = new Date()
date.setHours(12, 0, 0, 0)
const today = date.getTime() / 1000

export const appCRMSlice = createSlice({
  name: 'appCRM',
  initialState: {
    uniqUser: uniqid(),
    today,
    firstLoad: true,
    selectedContact: null,
    selectedDelai: null,
    selectedRappel: null,
    selectedUser: null,
    dateRepere: new Date(),
    users: [],
    emails: [],
    contacts: [],
    delais: [],
    rappels: [],
    qualites: [],
    metiers: [],
    structures: [],
    statuts: [],
    typesecheance: []
  },
  reducers: {
    reloadAllData(state) {
      state.firstLoad = true
    },
    clearSelectedContact(state) {
      state.selectedContact = null
    },
    clearSelectedDelai(state) {
      state.selectedDelai = null
    },
    clearSelectedRappel(state) {
      state.selectedRappel = null
    },
    clearSelectedUser(state) {
      state.selectedUser = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.contacts = action.payload.contacts
        state.delais = action.payload.delais
        state.rappels = action.payload.rappels
        state.users = action.payload.users
        state.emails = action.payload.emails

        state.qualites = uniqValues(action.payload.contacts, 'qualite')
        state.metiers = uniqValues(action.payload.contacts, 'metier')
        state.structures = uniqValues(action.payload.contacts, 'structure')
        state.statuts = uniqValues(action.payload.contacts, 'statut')

        state.typesecheance = getTypesEcheance(action.payload.contacts)

        state.firstLoad = false
      })
      .addCase(getAllData.rejected, (state, action) => {
        console.log('Erreur getAllDAta')
        console.log(action)
      })
      .addCase(updateAppOption.fulfilled, (state, action) => {
        state[action.payload.key] = action.payload.val
      })
      .addCase(getAppOption.fulfilled, (state, action) => {
        state[action.payload.key] = action.payload.val
      })
      .addCase(addContact.fulfilled, (state, action) => {
        const allContacts = state.contacts

        const addPost = {
          ...action.payload
        }

        allContacts.push(addPost)
        state.contacts = allContacts

        state.qualites = uniqValues(allContacts, 'qualite')
        state.metiers = uniqValues(allContacts, 'metier')
        state.structures = uniqValues(allContacts, 'structure')
        state.statuts = uniqValues(allContacts, 'statut')

        state.typesecheance = getTypesEcheance(allContacts)
      })
      .addCase(addContact.rejected, (state, action) => {
        console.log('Erreur addContact')
        console.log(action)
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        const allContacts = state.contacts

        const updatedPost = {
          ...action.payload
        }

        const newAllContacts = allContacts.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.contacts = newAllContacts

        state.qualites = uniqValues(newAllContacts, 'qualite')
        state.metiers = uniqValues(newAllContacts, 'metier')
        state.structures = uniqValues(newAllContacts, 'structure')
        state.statuts = uniqValues(newAllContacts, 'statut')

        state.typesecheance = getTypesEcheance(newAllContacts)
      })
      .addCase(updateContact.rejected, (state, action) => {
        console.log('Erreur updateContact')
        console.log(action)
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        const allContacts = state.contacts
        const newAllContacts = allContacts.filter(
          (post) => post.id !== action.payload.id
        )

        state.contacts = newAllContacts

        state.qualites = uniqValues(newAllContacts, 'qualite')
        state.metiers = uniqValues(newAllContacts, 'metier')
        state.structures = uniqValues(newAllContacts, 'structure')
        state.statuts = uniqValues(newAllContacts, 'statut')

        state.typesecheance = getTypesEcheance(newAllContacts)
      })
      .addCase(deleteContact.rejected, (state, action) => {
        console.log('Erreur deleteContact')
        console.log(action)
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.selectedContact = action.payload
      })
      .addCase(getContact.rejected, (state, action) => {
        state.selectedContact = 'error'
        console.log('Erreur getContact')
        console.log(action)
      })
      .addCase(addDelai.fulfilled, (state, action) => {
        const allDelais = state.delais

        const addPost = {
          ...action.payload
        }

        allDelais.push(addPost)
        state.delais = allDelais
      })
      .addCase(addDelai.rejected, (state, action) => {
        console.log('Erreur addDelai')
        console.log(action)
      })
      .addCase(updateDelai.fulfilled, (state, action) => {
        const allDelais = state.delais

        const updatedPost = {
          ...action.payload
        }

        const newAllDelais = allDelais.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.delais = newAllDelais
      })
      .addCase(updateDelai.rejected, (state, action) => {
        console.log('Erreur updateDelai')
        console.log(action)
      })
      .addCase(deleteDelai.fulfilled, (state, action) => {
        const allDelais = state.delais
        const newAllDelais = allDelais.filter(
          (post) => post.id !== action.payload.id
        )

        state.delais = newAllDelais
      })
      .addCase(deleteDelai.rejected, (state, action) => {
        console.log('Erreur deleteDelai')
        console.log(action)
      })
      .addCase(getDelai.fulfilled, (state, action) => {
        state.selectedDelai = action.payload
      })
      .addCase(getDelai.rejected, (state, action) => {
        state.selectedDelai = 'error'
        console.log('Erreur getDelai')
        console.log(action)
      })
      .addCase(addRappel.fulfilled, (state, action) => {
        const allRappels = state.rappels

        const addPost = {
          ...action.payload
        }

        allRappels.push(addPost)
        state.rappels = allRappels
      })
      .addCase(addRappel.rejected, (state, action) => {
        console.log('Erreur addRappel')
        console.log(action)
      })
      .addCase(updateRappel.fulfilled, (state, action) => {
        const allRappels = state.rappels

        const updatedPost = {
          ...action.payload
        }

        const newAllRappels = allRappels.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.rappels = newAllRappels
      })
      .addCase(updateRappel.rejected, (state, action) => {
        console.log('Erreur updateRappel')
        console.log(action)
      })
      .addCase(deleteRappel.fulfilled, (state, action) => {
        const allRappels = state.rappels
        const newAllRappels = allRappels.filter(
          (post) => post.id !== action.payload.id
        )

        state.rappels = newAllRappels
      })
      .addCase(deleteRappel.rejected, (state, action) => {
        console.log('Erreur deleteRappel')
        console.log(action)
      })
      .addCase(getRappel.fulfilled, (state, action) => {
        state.selectedRappel = action.payload
      })
      .addCase(getRappel.rejected, (state, action) => {
        state.selectedRappel = 'error'
        console.log('Erreur getRappel')
        console.log(action)
      })
      .addCase(addUser.fulfilled, (state, action) => {
        const allUsers = state.users

        const addPost = {
          ...action.payload
        }

        allUsers.push(addPost)
        state.users = allUsers
      })
      .addCase(addUser.rejected, (state, action) => {
        console.log('Erreur addUser')
        console.log(action)
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const allUsers = state.users

        const updatedPost = {
          ...action.payload
        }

        const newAllUsers = allUsers.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.users = newAllUsers
      })
      .addCase(updateUser.rejected, (state, action) => {
        console.log('Erreur updateUser')
        console.log(action)
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const allUsers = state.users
        const newAllUsers = allUsers.filter(
          (post) => post.id !== action.payload
        )

        state.users = newAllUsers
      })
      .addCase(deleteUser.rejected, (state, action) => {
        console.log('Erreur deleteUser')
        console.log(action)
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getUser.rejected, (state, action) => {
        state.selectedUser = 'error'
        console.log('Erreur getUser')
        console.log(action)
      })
  }
})

// on extrait les actions et le reducer
const { actions } = appCRMSlice
// on export chaque action individuellement
export const {
  reloadAllData,
  clearSelectedContact,
  clearSelectedDelai,
  clearSelectedRappel,
  clearSelectedUser
} = actions

export default appCRMSlice.reducer
